//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  http_login_system
} from "@/api/login.js"
import {
  setToken
} from '@/utils/auth'


export default {
  name: "Login",
  data() {
    return {
      userinfo: {
        username: "",
        password: ""
      }
    }
  },
  methods: {
    SubmitLogin() {
      console.log(process.env);
      if (this.userinfo.username.length < 11) {
        this.$message.error("对不起，手机号码必填11位，注意前后空格");
        return false;
      }
      if (this.userinfo.password.length < 6) {
        this.$message.error("对不起，密码必须6位以上，注意前后空格");
        return false;
      }
      http_login_system(this.userinfo).then(res => {
        if(res.code==0){
          setToken(res.data.token);
          localStorage.setItem("laravel_userinfo",JSON.stringify(res.data.userinfo));
          this.$router.push("/home/session");
        }
      });
    }
  }
}
